/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { PropsWithChildren } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';

const Layout = ({ children }: PropsWithChildren<{}>): React.ReactElement => {
    return (
        <>
            <div>
                <main>{children}</main>
                <footer className="text-gray-600 text-center">
                    © {new Date().getFullYear()} <span>Beyond Artful</span>
                </footer>
            </div>
        </>
    );
};

Layout.propTypes = {
    children: PropTypes.node.isRequired,
};

export default Layout;
